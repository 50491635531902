<template>
  <button
    type="button"
    :class="$style.button"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts" setup></script>

<style module lang="scss">
.button {
  width: 100%;
  margin: 0;
  padding: 0;
  font: inherit;
  text-align: left;
  background: 0;
  border: 0;
}
</style>
