<template>
  <nav
    :class="{ [$style.menu]: true, [$style.active]: isMenuActive }"
    @mouseover="setMenuState(true)"
    @mouseleave="setMenuState(false)"
  >
    <ul :class="{ [$style.list]: true, [$style.active]: isMenuActive }">
      <li v-if="isBackMenuShown && false">
        <NavigatableItem
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          loop="up"
          data-testid="nav-0"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          @click="onRouterBack"
        >
          <span :class="[$style.icon, $style.itemIconBack]">
            <ArrowUpIcon />
          </span>

          <span :class="$style.text">{{ $t('appMenu.back') }}</span>
        </NavigatableItem>
      </li>
      <li
        v-if="user && _profile"
        :class="$style.profile"
      >
        <NavigatableItem
          :class="[$style.item, $style.profile]"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          @click="onClickProfileSection()"
          data-testid="nav-1"
        >
          <span
            v-if="_profile.kind !== ProfileType.Child"
            :class="[$style.icon, $style.iconProfile]"
          >
            {{ _profile.name[0] }}
          </span>
          <span
            v-else
            :class="[$style.icon, $style.iconChild]"
          >
            <ChildIcon />
          </span>

          <span :class="$style.text">{{ _profile.name }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          @click="onClickMenuSection($RouterPage.MainPage)"
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          data-testid="nav-2"
        >
          <span :class="$style.icon">
            <IconMain />
          </span>

          <span :class="$style.text">{{ $t('appMenu.main') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          @click="onClickMenuSection($RouterPage.SearchPage)"
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          data-testid="nav-3"
        >
          <span :class="$style.icon">
            <IconSearch />
          </span>

          <span :class="$style.text">{{ $t('appMenu.search') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          @click="onClickMenuSection($RouterPage.CatalogPage)"
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          data-testid="nav-4"
        >
          <span :class="$style.icon">
            <IconCatalog />
          </span>

          <span :class="$style.text">{{ $t('appMenu.catalog') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          @click="onClickMenuSection($RouterPage.CollectionPage)"
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          data-testid="nav-5"
        >
          <span :class="$style.icon">
            <IconSave />
          </span>

          <span :class="$style.text">{{ $t('appMenu.collection') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          @click="onClickMenuSection($RouterPage.ChannelsPage)"
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          data-testid="nav-6"
        >
          <span :class="$style.icon">
            <IconTv />
          </span>

          <span :class="$style.text">{{ $t('appMenu.tv') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          @click="onClickMenuSection($RouterPage.MyChannelPage)"
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          data-testid="nav-7"
        >
          <span :class="$style.icon">
            <IconKinom />
          </span>

          <span :class="$style.text">{{ $t('appMenu.myChannel') }}</span>
        </NavigatableItem>
      </li>
      <li>
        <NavigatableItem
          :class="{ [$style.item]: true, [$style.active]: $route.name === $RouterPage.AuthPage }"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          data-testid="nav-8"
          loop="down"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
          @click="onClickSettingsSection"
        >
          <span :class="$style.icon">
            <IconSettings />
          </span>

          <span :class="$style.text">{{ $t('appMenu.settings') }}</span>
        </NavigatableItem>
      </li>
      <li v-if="!isRelease">
        <NavigatableItem
          @click="onClickMenuSection($RouterPage.LogInfo)"
          :class="$style.item"
          :active-class="$style.active"
          :tag="AppSlotButton"
          group="nav"
          data-testid="nav-10"
          loop="down"
          @focus="setMenuState(true)"
          @blur="setMenuState(false)"
        >
          <span :class="$style.icon">
            <InfoIcon />
          </span>

          <span :class="$style.text">{{ $t('appMenu.log') }}</span>
        </NavigatableItem>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { AnyFunction } from '@development/video-player/lib/base/function';
import { TvKeyCode } from '@development/viju-frontend-sdk';
import {
  analyticService,
  AppKeyboardEvent,
  keyboardEventHandler,
  useAccountPageAnalytics,
  useCatalogPageAnalytics,
  useMainPageAnalytics,
  useMyChannelAnalytics,
  useMyCollectionPageAnalytics,
  useSearchPageAnalytics,
  useTvPageAnalytics,
} from '@development/viju-frontend-sdk/smarttv';
import { ProfileType } from '@development/viju-frontend-sdk/smarttv';
import { RouterPage } from '@development/viju-frontend-sdk/smarttv';
import { useMouseDetect } from '@development/viju-frontend-sdk/smarttv';
import { appNavigationService, environmentService, routerService } from '@development/viju-frontend-sdk/smarttv';
import { useAuthActions } from '@development/viju-frontend-sdk/smarttv';
import { useSessionStore } from '@development/viju-frontend-sdk/smarttv';
import { useAuthStore } from '@development/viju-frontend-sdk/smarttv';
import InfoIcon from '@development/viju-frontend-sdk/smarttv-assets/icons/33x33/info.svg';
import IconCatalog from '@development/viju-frontend-sdk/smarttv-assets/icons/40x40/catalog.svg';
import IconKinom from '@development/viju-frontend-sdk/smarttv-assets/icons/40x40/kinom.svg';
import IconMain from '@development/viju-frontend-sdk/smarttv-assets/icons/40x40/main.svg';
import IconSave from '@development/viju-frontend-sdk/smarttv-assets/icons/40x40/save.svg';
import IconSearch from '@development/viju-frontend-sdk/smarttv-assets/icons/40x40/search.svg';
import IconSettings from '@development/viju-frontend-sdk/smarttv-assets/icons/40x40/settings.svg';
import IconTv from '@development/viju-frontend-sdk/smarttv-assets/icons/40x40/tv.svg';
import ArrowUpIcon from '@development/viju-frontend-sdk/smarttv-assets/icons/51x51/arrow-up.svg';
import ChildIcon from '@development/viju-frontend-sdk/smarttv-assets/icons/51x51/child.svg';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

const isMenuActive = ref(false);
const isRelease = environmentService.getVariable('isRelease');

const route = useRoute();
const { isMouseDetected } = useMouseDetect();
const { isAuth, _profile, user } = storeToRefs(useSessionStore());
const authStore = useAuthStore();
const { openAuthPage } = useAuthActions();

const mainPageAnalytics = useMainPageAnalytics();
const tvPageAnalytics = useTvPageAnalytics();
const mychannelAnalytics = useMyChannelAnalytics();
const searchAnalytics = useSearchPageAnalytics();
const myCollectionAnalytics = useMyCollectionPageAnalytics();
const catalogPageAnalytics = useCatalogPageAnalytics();
const accountPageAnalytics = useAccountPageAnalytics();

const setMenuState = (isOpen: boolean) => {
  isMenuActive.value = isOpen;
};

const onRouterBack = (event: KeyboardEvent) => routerService.onBackspace(new AppKeyboardEvent(event));

type AppMenuRouterName =
  | RouterPage.LogInfo
  | RouterPage.MainPage
  | RouterPage.ChannelsPage
  | RouterPage.MyChannelPage
  | RouterPage.SearchPage
  | RouterPage.CollectionPage
  | RouterPage.CatalogPage;

const gotoAnalyticEventDict: Record<AppMenuRouterName, AnyFunction> = {
  [RouterPage.LogInfo]: () => ({}),
  [RouterPage.MainPage]: () => mainPageAnalytics.onGotoMainPage(),
  [RouterPage.ChannelsPage]: () => tvPageAnalytics.onGotoTvChannelsPage(),
  [RouterPage.MyChannelPage]: () => mychannelAnalytics.onGotoMyChannelPage(),
  [RouterPage.SearchPage]: () => searchAnalytics.onGotoSearchPage({ page: analyticService.getAnalyticPageName() }),
  [RouterPage.CollectionPage]: () => myCollectionAnalytics.onGotoCollectionsPage(analyticService.getAnalyticPageName()),
  [RouterPage.CatalogPage]: () => catalogPageAnalytics.onGotoCatalogPage(),
};

const onClickMenuSection = (name: AppMenuRouterName) => {
  const handler = gotoAnalyticEventDict[name];

  if (handler) {
    Reflect.apply(handler, undefined, []);
  }

  return routerService.push({ name });
};

const onClickProfileSection = () => {
  authStore.setBackUrl(route.fullPath);

  accountPageAnalytics.onGotoProfilesPage(analyticService.getAnalyticPageName());

  return routerService.push({ name: RouterPage.ProfilesPage });
};

const onClickSettingsSection = () => {
  if (!isAuth.value) {
    return openAuthPage();
  }

  accountPageAnalytics.onGotoAccountPage(analyticService.getAnalyticPageName());

  return routerService.push({ name: RouterPage.SettingsPage });
};

const isBackMenuShown = computed(
  () => !isRelease && isMouseDetected.value && appNavigationService.hasPreviousRoute.value,
);

const onRightArrowButtonUp = () => {
  if (!isMenuActive.value) {
    return;
  }

  if (appNavigationService.activeNavigationGroup.value === 'nav') {
    setMenuState(false);
  }
};

const onLeftArrowButtonUp = () => {
  if (isMenuActive.value) {
    return;
  }

  if (appNavigationService.activeNavigationGroup.value === 'nav') {
    setMenuState(true);
  }
};

keyboardEventHandler.on(TvKeyCode.RIGHT, onRightArrowButtonUp);
keyboardEventHandler.on(TvKeyCode.LEFT, onLeftArrowButtonUp);
</script>

<style module lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/fonts.scss';
@import '@/styles/colors.scss';
@import '@/styles/layers.scss';

.menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($map: $layers, $key: --z-index-nav);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: map-get($map: $colors, $key: --c-nav-shadow);

  &.active {
    background: map-get($map: $colors, $key: --c-nav-shadow-active);

    .item.profile:after {
      visibility: visible;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  width: adjustPx(80px);
  margin: adjustPx(54px) adjustPx(18px) 0;
  overflow: hidden;

  li {
    margin-bottom: adjustPx(23px);

    &.profile {
      margin-bottom: adjustPx(20px);
    }
  }

  &.active {
    min-width: adjustPx(337px);
  }
}

.item {
  display: flex;
  align-items: center;
  min-height: adjustPx(80px);
  color: map-get($map: $colors, $key: --c-white-color);
  white-space: nowrap;
  text-decoration: none;
  border-radius: adjustPx(24px);
  outline: none;

  &.active {
    background-color: map-get($map: $colors, $key: --c-border-color);
  }

  &.profile {
    margin-bottom: adjustPx(15px);
    background-color: unset;

    &:after {
      position: absolute;
      top: adjustPx(95px);
      height: adjustPx(2px);
      background-color: map-get($map: $colors, $key: --c-border-color);
      visibility: hidden;
      content: '';
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: map-get($map: $colors, $key: --c-hover-color);
  }

  svg {
    width: adjustPx(40px);
    height: adjustPx(40px);
  }
}

.itemIconBack {
  transform: rotate(-90deg);
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: adjustPx(80px);

  &Profile {
    background-color: map-get($map: $colors, $key: --c-border-color);
  }

  &Child {
    background: linear-gradient(90deg, #46b1ab 0%, #467eb1 0.01%, #9d62c1 100%);
  }

  &Profile,
  &Child {
    min-width: adjustPx(67px);
    min-height: adjustPx(67px);
    margin-left: adjustPx(7px);
    border-radius: 50%;
  }
}

.text {
  @include f-body-2;
  flex: 1;
  margin-left: adjustPx(16px);
}
</style>
