<template>
  <NavigatableItem
    :class="classNames"
    ref="item"
    :data-navigatable="group"
    :data-loop="loop"
    :data-navigatable-active="isActive"
  />
</template>

<script setup lang="ts">
import { appNavigationService } from '@development/viju-frontend-sdk/smarttv';
import { Component, ComponentPublicInstance, computed, h, ref, useCssModule, useSlots, watch } from 'vue';

import UIButton from '@/components/button/UIButton.vue';

interface Props {
  group: string;
  tag?: any;
  activeClass?: string;
  loop?: string;
}

const props = withDefaults(defineProps<Props>(), {
  activeClass: '',
  tag: UIButton,
});

const style = useCssModule();

const emit = defineEmits<{
  (event: 'active', el?: HTMLElement): void;
  (event: 'inactive', el?: HTMLElement): void;
  (event: 'mounted', el?: HTMLElement): void;
}>();

const item = ref<HTMLElement & Pick<ComponentPublicInstance, '$el'>>();
const isActive = ref(false);
const element = computed<HTMLElement>(() => item.value?.$el ?? item.value);

const setActiveCurrentItem = (active: boolean) => {
  if (isActive.value && !active) {
    emit('inactive', element.value);
  }

  isActive.value = active;

  if (!active) {
    return;
  }

  emit('active', element.value);
};

const classNames = computed(() => ({
  [style.navigatable]: true,
  [style.active]: !props.activeClass && isActive.value,
  [props.activeClass]: props.activeClass && isActive.value,
}));

watch(appNavigationService.activeNavigationItem, (activeNavigationItemValue) => {
  if (!activeNavigationItemValue) {
    return;
  }

  setActiveCurrentItem(activeNavigationItemValue === element.value);
});

const NavigatableItem = computed(() => h(props.tag as Component, {}, useSlots()));
</script>

<style module lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

.navigatable {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    content: '';
  }

  &.active::after {
    box-shadow: inset 0 0 0 adjustPx(7px) map-get($map: $colors, $key: --c-hover-color);
  }
}

.highlight {
  box-shadow: inset 0 0 0 2px map-get($map: $colors, $key: --c-info-color);

  &::after {
    box-shadow: inset 0 0 0 2px map-get($map: $colors, $key: --c-info-color);
  }
}
</style>
