import '@development/dsml-js';
import './styles/app.scss';
import './polyfills';
import 'dayjs/locale/ru';
import '@development/video-player/lib/style.css';
import '@development/viju-frontend-sdk/dist/assets/style.css';

import { SmartTvVijuPlayer } from '@development/video-player';
import { AnalyticEventNameNew, AnalyticPageName } from '@development/viju-frontend-sdk';
import {
  alertService,
  analyticService,
  appNavigationService,
  authService,
  deviceService,
  environmentService,
  globalSettings,
  i18n,
  initStore,
  logger,
  longPressDirective,
  RouterPage,
  routerService,
  telemetry,
  useContentStore,
  useSessionStore,
} from '@development/viju-frontend-sdk/smarttv';
import * as Sentry from '@sentry/browser';
import Axios, { isCancel } from 'axios';
import cryptoJs from 'crypto-js';
import dayjs from 'dayjs';
import JwtDecode from 'jwt-decode';
import QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';
import { computed, inject, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { createApp } from 'vue';
import * as router from 'vue-router';
import Vuex from 'vuex';

import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

import App from './App.vue';
import routes from './services/router/routes';

dayjs.locale('ru');

const LOG_LEVEL = 6;

const setupLoggers = () => {
  window.$vijuPlayer?.logger?.setLevel(LOG_LEVEL);
  logger.setLevel(LOG_LEVEL);
};

const setupPlayer = () => {
  SmartTvVijuPlayer.setCurrency('₽');
  SmartTvVijuPlayer.setLang('ru');
};

const showAppVersion = () => {
  console.info(
    '%c Viju smarttv version: ' + '%c '.concat(environmentService.getVariable('appVersion') || '', ' '),
    'color: #fff; background: #0A1E1F',
    'color: #fff; background: #cc6666',
  );
};

const hideDebugData = () => {
  let debug = document.getElementById('debug1');

  if (debug) {
    debug.hidden = true;
  }

  debug = document.getElementById('debug2');
  if (debug) {
    debug.hidden = true;
  }

  debug = document.getElementById('debug3');
  if (debug) {
    debug.hidden = true;
  }
};

globalSettings.axios = Axios;
globalSettings.axiosIsCancel = isCancel;
globalSettings.computed = computed;
globalSettings.ref = ref;
globalSettings.onMounted = onMounted;
globalSettings.vuex = Vuex;
globalSettings.router = router;
globalSettings.routes = routes;
globalSettings.nextTick = nextTick;
globalSettings.jwt = JwtDecode;
globalSettings.dayjs = dayjs;
globalSettings.qrcode = QRCode;
globalSettings.uuidv4 = uuidv4;
globalSettings.cryptoJs = cryptoJs;
globalSettings.inject = inject;
globalSettings.onUnmounted = onUnmounted;
globalSettings.sentry = Sentry;

async function main() {
  environmentService.init({});

  // Что-то старое осталось, пока страшно выпиливать
  hideDebugData();
  // Выставляем env переменные плеера
  setupPlayer();
  // Выставляем логгеры
  setupLoggers();
  // В логи кидаем инфу о версии приложения
  showAppVersion();

  // Инициализируем системные API
  await deviceService.init();
  // активируем навигацию
  appNavigationService.init();
  appNavigationService.initialize();
  alertService.initialize();
  // активируем хуки авторизации / род. контроля.
  routerService.initialize();
  authService.init();

  await analyticService.init();

  const app = createApp(App);

  globalSettings.vue = app;

  app.config.globalProperties.$RouterPage = RouterPage;

  const { router } = routerService;

  app.use(router).use(i18n).use(telemetry);

  initStore();

  const contentStore = useContentStore();
  const sessionStore = useSessionStore();

  try {
    await sessionStore.fetchUser({ forceFetchUser: true });
  } catch (error) {
    // it is ok
  }

  await contentStore.fetchGenres();
  await sessionStore.fetchOffers();

  analyticService.sendEvent({ name: AnalyticEventNameNew.AutoStartSession, page: AnalyticPageName.Main });

  // setupSentry(app, router)

  app.component('NavigatableItem', NavigatableItem);

  app.directive('long-press', longPressDirective);
  app.mount('#app');
}

main();
