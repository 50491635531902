<template>
  <AppMenu v-if="!isMenuHidden" />
  <AppBuildBadge v-if="isBuildBadgeShown" />
  <RouterView v-slot="{ Component }">
    <KeepAlive
      :include="[
        $RouterPage.CatalogPage,
        $RouterPage.SearchPage,
        $RouterPage.ChannelsPage,
        $RouterPage.MediaCardSimilarPage,
        $RouterPage.MyChannelPage,
      ]"
      :max="1"
      :key="cacheKey"
    >
      <component :is="Component" />
    </KeepAlive>
  </RouterView>
  <UIAlert />
</template>

<script setup lang="ts">
import { SmartTvVijuPlayer } from '@development/video-player';
import { isDefined } from '@development/viju-frontend-sdk';
import { onUnexpectedError, useMainPageStore } from '@development/viju-frontend-sdk/smarttv';
import { RouterPage } from '@development/viju-frontend-sdk/smarttv';
import { logger } from '@development/viju-frontend-sdk/smarttv';
import { useContentStore, useSessionStore, useTvChannelsStore } from '@development/viju-frontend-sdk/smarttv';
import { channelsService, contentCacheManager } from '@development/viju-frontend-sdk/smarttv';
import * as Sentry from '@sentry/browser';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, onErrorCaptured, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppBuildBadge from '@/components/app-build-badge/AppBuildBadge.vue';
import AppMenu from '@/components/menu/AppMenu.vue';

import UIAlert from './components/alert/UIAlert.vue';

const route = useRoute();

const contentStore = useContentStore();
const sessionStore = useSessionStore();
const tvChannelsStore = useTvChannelsStore();
const mainPageStore = useMainPageStore();

const { currentOffer, _profile } = storeToRefs(sessionStore);

const isMenuHidden = ref(false);
const cacheKey = ref(uuidv4());

// Перед релизом на прод, будем скрывать для прода - оставлять для остальных
const isBuildBadgeShown = computed(() => route.path.includes('settings') || route.path.includes('auth'));

const hiddenAppMenuRoutes = [
  RouterPage.MediaCardPlayerPage,
  RouterPage.ChannelsPlayerPage,
  RouterPage.ProfilesPage,
  RouterPage.MediaCardSeriesPage,
  RouterPage.Offers,
];

const cachedRoutes = [
  RouterPage.MediaCardControls,
  RouterPage.MediaCardDescription,
  RouterPage.MediaCardPersons,
  RouterPage.CatalogPage,
  RouterPage.CollectionPage,
  RouterPage.SearchPage,
  RouterPage.ChannelsPage,
  RouterPage.ChannelsPlayerPage,
  RouterPage.Offers,
  RouterPage.OfferInfo,
  RouterPage.MyChannelPage,
  RouterPage.AuthPage,
  RouterPage.MediaCardSimilarPage,
];

const onDidProfileUpdated = (newProfile: string, oldProfile: string) => {
  if (oldProfile || !newProfile) {
    contentStore.reset();
    tvChannelsStore.reset();

    channelsService.fetchChannels();
    contentStore.fetchGenres();
    sessionStore.fetchOffers();
    contentCacheManager.clear();
    mainPageStore.reset();

    cacheKey.value = uuidv4();
  }
};

const shouldResetCache = (to: RouterPage, from: RouterPage) => {
  return to === RouterPage.CollectionPage && from === RouterPage.MyChannelPage;
};

const onBeforeEach = (to: RouterPage, from: RouterPage) => {
  if (isDefined(to) && hiddenAppMenuRoutes.includes(to)) {
    isMenuHidden.value = true;
    return;
  }

  if (isDefined(to) && isDefined(from) && !cachedRoutes.includes(to)) {
    cacheKey.value = uuidv4();
  }

  if (shouldResetCache(to, from)) {
    cacheKey.value = uuidv4();
  }

  isMenuHidden.value = false;
};

const onOfferUpdated = () => {
  SmartTvVijuPlayer.setSession({ offer: currentOffer.value });
};

watch(() => route.name, onBeforeEach);
watch(() => _profile.value?.kind, onDidProfileUpdated);
watch(currentOffer, onOfferUpdated, { immediate: true });

// Скорей всего, если мы оказались тут - то у нас крашнулось приложение
onErrorCaptured((error) => {
  onUnexpectedError(error);
  logger.error(error?.toString());
  logger.error(error?.stack as string);

  Sentry.captureException(error);

  throw error;
});
</script>
