<template>
  <div :class="$style.build">
    {{ appVersion }}
  </div>
</template>

<script lang="ts" setup>
import { environmentService } from '@development/viju-frontend-sdk/smarttv';

const appVersion = environmentService.getVariable('appVersion');
</script>

<style lang="scss" module>
@import '@/styles/colors.scss';

.build {
  position: absolute;
  right: 10px;
  z-index: 10000;
  color: map-get($map: $colors, $key: --c-white-color);
  font-size: 10px;
  font-family: monospace;
  pointer-events: none;
}
</style>
